import Masonry from "@mui/lab/Masonry";

import goldenHour from "./photos/golden_hour_cmpr.jpg";
import lalaLand from "./photos/lala_land_cmpr.jpg";
import fixYou2023 from "./photos/fix_you_2023_cmpr.jpg";
import gabrielsOboe from "./photos/gabriels_oboe_cmpr.jpg";
import sayYouWont2019 from "./photos/say_you_wont_2019_cmpr.jpg";
import sayYouWont2018 from "./photos/say_you_wont_2018_cmpr.jpg";
import californiaDreaming from "./photos/california_dreaming_cmpr.jpg";
import fixYou2018 from "./photos/fix_you_2018_cmpr.jpg";
import beethoven from "./photos/beethoven_cmpr.jpg";
import gravity from "./photos/gravity_cmpr.jpg";
import tango from "./photos/tango_cmpr.jpg";
import secrets2017 from "./photos/secrets_2017_cmpr.jpg";
import secrets2018 from "./photos/secrets_2018_cmpr.jpg";
import taylor from "./photos/taylor_cmpr.jpg";
import arrivalBirds from "./photos/arrival_birds_cmpr.jpg";
import demons from "./photos/demons_cmpr.jpg";
import lotr from "./photos/lotr_cmpr.jpg";
import found from "./photos/found_cmpr.jpg";
// import { ProgressiveImage } from "./ProgressiveImg";

const items: CardProps[] = [
  {
    title: "golden hour",
    occasion: "exhibition @ sharks ice",
    date: "jan 2024",
    city: "san jose, ca",
    videoUrl: "https://www.youtube.com/watch?v=p-sF_bXyDg4",
    imgUrl: goldenHour,
    // placeholderUrl: goldenHour,
  },
  {
    title: "fix you",
    videoUrl: "https://www.youtube.com/watch?v=Ob7ScQZuZjU",
    occasion: "crystalline classic",
    date: "2023",
    city: "santa rosa, ca",
    imgUrl: fixYou2023,
  },
  {
    title: "la la land",
    videoUrl: "https://www.youtube.com/watch?v=F0KTqWV_gNo",
    occasion: "stillwell winter warmup",
    date: "2023",
    city: "artesia, ca",
    imgUrl: lalaLand,
  },
  {
    title: "you will be found",
    videoUrl: "https://www.youtube.com/watch?v=QXLpMlSLQls",
    occasion: "MIT FSC winter exhibition",
    date: "2019",
    city: "cambridge, ma",
    imgUrl: found,
  },
  {
    title: "gabriel's oboe",
    videoUrl: "https://www.youtube.com/watch?v=4LWySjjHwQ4",
    occasion: "crystalline classic",
    date: "2022",
    city: "santa rosa, ca",
    imgUrl: gabrielsOboe,
  },
  {
    title: "california dreaming",
    videoUrl: "https://www.youtube.com/watch?v=dVx1uFKf8n8",
    occasion: "MIT FSC spring exhibition",
    date: "2019",
    city: "cambridge, ma",
    imgUrl: californiaDreaming,
  },
  {
    title: "say you won't let go",
    videoUrl: "https://www.youtube.com/watch?v=kzZoaeZ14U4",
    occasion: "MIT FSC competition",
    date: "2019",
    city: "cambridge, ma",
    imgUrl: sayYouWont2019,
  },
  {
    title: "say you won't let go",
    videoUrl: "https://www.youtube.com/watch?v=schY87tP2WE",
    occasion: "MIT FSC winter exhibition",
    date: "2018",
    city: "cambridge, ma",
    imgUrl: sayYouWont2018,
  },
  {
    title: "fix you",
    videoUrl: "https://www.youtube.com/watch?v=jtTTIMPS8Ok",
    occasion: "MIT FSC winter exhibition",
    date: "2018",
    city: "cambridge, ma",
    imgUrl: fixYou2018,
  },
  {
    title: "secrets",
    videoUrl: "https://www.youtube.com/watch?v=_YxeAV-cxxs",
    occasion: "MIT FSC competition",
    date: "2018",
    city: "cambridge, ma",
    imgUrl: beethoven,
  },
  {
    title: "secrets",
    videoUrl: "https://www.youtube.com/watch?v=lpRpAYUyLPY",
    occasion: "US collegiate championships",
    date: "aug 2018",
    city: "adrian, mi",
    imgUrl: secrets2018,
  },
  {
    title: "gravity",
    videoUrl: "https://www.youtube.com/watch?v=vDOsSZ7ZwaI",
    occasion: "MIT FSC spring exhibition",
    date: "2018",
    city: "cambridge, ma",
    imgUrl: gravity,
  },
  {
    title: "demons",
    videoUrl: "https://www.youtube.com/watch?v=qP4D3_APS0Q",
    occasion: "MIT FSC exhibition",
    date: "2017",
    city: "cambridge, ma",
    imgUrl: demons,
  },
  {
    title: "old vs new taylor",
    videoUrl: "https://www.youtube.com/watch?v=0QA-NiIQmnc",
    occasion: "MIT FSC winter exhibition",
    date: "2017",
    city: "cambridge, ma",
    imgUrl: taylor,
  },
  {
    title: "tango de los exilados",
    videoUrl: "https://www.youtube.com/watch?v=zmus2no6rEs",
    occasion: "US collegiate championships",
    date: "2017",
    city: "adrian, mi",
    imgUrl: tango,
  },
  {
    title: "secrets",
    videoUrl: "https://www.youtube.com/watch?v=zmus2no6rEs&t=265s",
    occasion: "US collegiate championships",
    date: "2017",
    city: "adrian, mi",
    imgUrl: secrets2017,
  },
  {
    title: "arrival of the birds",
    videoUrl: "https://www.youtube.com/watch?v=M_segYbQPmI",
    occasion: "pasadena open championships",
    date: "2016",
    city: "pasadena, ca",
    imgUrl: arrivalBirds,
  },
  {
    title: "lord of the rings",
    videoUrl: "https://www.youtube.com/watch?v=VW_4avPidrY",
    occasion: "pasadena open championships",
    date: "2016",
    city: "pasadena, ca",
    imgUrl: lotr,
  },
];

function Homepage() {
  return (
    <div className="font-sans">
      <div className="mt-10 mb-12 w-full flex justify-center text-2xl font-medium">
        shannen wu
      </div>
      <div className="w-full flex justify-center">
        <Masonry columns={3} spacing={2}>
          {items.map((item, i) => (
            <div key={i} className="relative">
              <Card {...item} />
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  );
}

type CardProps = {
  title: string;
  occasion: string;
  date: string;
  city: string | null;
  imgUrl: string;
  // placeholderUrl: string;
  videoUrl: string;
};

function Card(props: CardProps) {
  const cardClassname =
    "hover:opacity-80 opacity-0 transition-opacity duration-300 cursor-pointer bg-slate-50 absolute inset-0 flex justify-center items-center";
  return (
    <>
      <img
        className="rounded"
        src={props.imgUrl}
        // placeholder={"TODO"}
        alt={props.title}
        loading="lazy"
      />
      <a
        href={props.videoUrl}
        target="_blank"
        rel="noreferrer"
        className={cardClassname}
      >
        <div className="max-w-80">
          <div className="text-2xl font-bold">{props.title}</div>
          <div className="text-xl text-slate-500">{props.occasion}</div>
          <div className="text-xl text-slate-500">
            {props.city} <span className="text-xs">•</span> {props.date}
          </div>
        </div>
      </a>
    </>
  );
}

export default Homepage;
